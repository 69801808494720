import React from "react";
import Modal from "react-bootstrap/Modal";

const HearscoreResult = ({
  hearscoreResult,
  handleCloseHearscore,
  earData,
}) => {
  return (
    <>
      <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Hearscore Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-3 text-end">
              Left Ear: <span className="h5">{hearscoreResult.left}</span>
              <br />
              {!earData.left && <b>No data</b>}
            </div>
            <div className="col text-center">
              {hearscoreResult.overall && hearscoreResult.overall > 0 && (
                <img
                  src={`/Images/SB-Dial-${hearscoreResult.overall}.png`}
                  className="w-75"
                />
              )}
            </div>
            <div className="col-sm-3 text-start">
              Right Ear: <span className="h5">{hearscoreResult.right}</span>
              <br />
              {!earData.right && <b>No data</b>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => handleCloseHearscore()}
            title="Cancel"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default HearscoreResult;
