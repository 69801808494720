import React from "react";

const DisplayError = ({ errorMessage }) => {
  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <div className="alert alert-danger text-center w-50" role="alert">
          {errorMessage}
        </div>
      </div>
    </>
  );
};
export default DisplayError;
