import React from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <>
      <header>
        <Navbar fixed="top" expand="lg" className="bg-body-tertiary shadow">
          <Container fluid>
            <img
              src="/Images/logo.png"
              className="w-15"
              title="logo"
              alt="logo"
            />
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto"></Nav>
              {isAuthenticated && (
                <Nav className="ml-auto">
                  <span className="p-2 h6 mb-0">
                    Hello {accounts.length > 0 && accounts[0].name}
                  </span>
                  <NavDropdown
                    align={{ lg: "end" }}
                    title={<i className="fa-solid fa-circle-user fa-2xl"></i>}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item
                      onClick={() => handleLogout("popup")}
                      title="Logout"
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};
export default Header;
