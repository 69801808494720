import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    // log error
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }

      // default fallback UI
      return (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <div className="rounded bg-white p-4 text-center">
            <strong className="d-block fs-5 mb-1">
              An unexpected error has ocurred
            </strong>
            <p>If this problem persists, please contact support</p>
            <button
              className="d-block w-100 btn btn-primary mt-1"
              onClick={() => window.location.reload()}
              title="Refresh"
            >
              <span className="fa-solid fa-rotate-right me-2" />
              Refresh
            </button>
            <a
              href="/"
              className="d-block w-100 btn btn-dark mt-1"
              title="Go Home"
            >
              <span className="fa-solid fa-home me-2" />
              Go Home
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
