import React, { useState, useEffect } from "react";
import AudiogramChart from "./AudiogramChart";
import ManualAudiogramData from "./ManualAudiogramData";
import HearscoreResult from "./HearscoreResult";
import Loader from "./Common/Loader";
import DisplayError from "./Common/DisplayError";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";

const noResponseObject = {
  label: "No Response",
  value: "noresponse",
};

const CalculatorLayout = () => {
  let [finalAudiogramData, setFinalAudiogramData] = useState([]);
  let [audiogramData, setAudiogramData] = useState([]);
  let [selectVolumes, setSelectVolumes] = useState(null);

  const FREQUENCIES = [250, 500, 1000, 2000, 4000, 8000];
  const DECIBELS = [
    -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
  ];

  useEffect(() => {
    let vol = [];
    DECIBELS.map((decibel) => {
      vol.push({ label: decibel + "dB", value: decibel });
    });
    vol.push(noResponseObject);
    setSelectVolumes(vol);
  }, []);

  const [show, setShow] = useState(false);
  const [showHearscore, setShowHearscore] = useState(false);
  const [hearscoreResult, setHearscoreResult] = useState(null);
  const [earData, setEarData] = useState({ left: false, right: false });
  const [isCalculating, setIsCalculating] = useState(false);
  const [isErrorFound, setIsErrorFound] = useState(false);
  const [calculationApiError, setCalculationApiError] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseHearscore = () => setShowHearscore(false);
  const handleShowHearscore = () => setShowHearscore(true);

  let setManualAudiogram = (manualAudiogram) => {
    setFinalAudiogramData(manualAudiogram);
    handleClose();
  };

  let calculateHearScore = () => {
    setIsCalculating(true);
    setIsErrorFound(false);
    setCalculationApiError(null);

    let leftEarData = finalAudiogramData.filter((obj) => obj.channel == "left");
    let rightEarData = finalAudiogramData.filter(
      (obj) => obj.channel == "right"
    );

    setEarData({
      left: leftEarData.length > 0,
      right: rightEarData.length > 0,
    });

    //For live
    let audiogramData = finalAudiogramData;
    var config = {
      method: "post",
      url:
        "https://sbportal.alpaca360.com/api/sbsite/gethearscore?audiogramData=" +
        JSON.stringify(audiogramData),
      headers: {
        Authorization:
          "Bearer 485a789b-e751-4907-9a62-e05d597b24a24b5c5b32-5de0-4ea0-bbf1-8f6ae3ac3fb8",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios(config)
      .then((response) => {
        console.log(response);
        if (response.data.status == 200) {
          let result = response.data.data;
          setHearscoreResult(result);
          handleShowHearscore();
        } else {
          setIsErrorFound(true);
          setCalculationApiError(response.data.statusMessage);
        }
        setIsCalculating(false);
      })
      .catch((error) => {
        console.error(error);
        setIsErrorFound(true);
        setCalculationApiError(
          "There is an error while calculating a hearscore."
        );
        setIsCalculating(false);
      });

    // //Api is not working from local so it's for testing
    // setTimeout(() => {
    //   setIsCalculating(false);

    //   // if success
    //   let result = {
    //     left: 8.55,
    //     right: 8.5,
    //     overall: 9,
    //   };
    //   setHearscoreResult(result);
    //   handleShowHearscore();

    //   // if error
    //   // setIsErrorFound(true);
    //   // setCalculationApiError(
    //   //   "There is an error while calculating a hearscore."
    //   // );
    // }, 2000);
  };

  let updateFinalAudiogramData = (newAudiogram) => {
    const newAudiogramData = finalAudiogramData;

    let checkIfExist = newAudiogramData.find(
      (c) => c.channel == newAudiogram.channel && c.x == newAudiogram.x
    );

    if (checkIfExist) {
      if (!(newAudiogram.y == null || newAudiogram.y == undefined)) {
        checkIfExist.y = newAudiogram.y;
        checkIfExist.noResponse = newAudiogram.noResponse;
        setFinalAudiogramData((prevItems) =>
          prevItems.map((item) =>
            item.channel == newAudiogram.channel && item.x == newAudiogram.x
              ? checkIfExist
              : item
          )
        );
      } else {
        setFinalAudiogramData((prevItems) =>
          prevItems.filter((obj) => obj !== checkIfExist)
        );
      }
    } else {
      if (!(newAudiogram.y == null || newAudiogram.y == undefined)) {
        setFinalAudiogramData((prevItems) =>
          [...prevItems, newAudiogram].sort((a, b) => a.x - b.x)
        );
      }
    }
  };

  let reSetAudiogramData = (channel) => {
    if (channel) {
      setFinalAudiogramData((prevItems) =>
        prevItems.filter((obj) => obj.channel !== channel)
      );
    } else {
      setFinalAudiogramData([]);
    }
  };

  let handleAudiogramResultManually = (isEdit) => {
    if (isEdit) {
      setAudiogramData(finalAudiogramData);
    } else {
      setAudiogramData([]);
    }
    handleShow();
  };

  return (
    <>
      {isCalculating && <Loader message="Calculating Hearscore..." />}
      <AudiogramChart
        frequency={FREQUENCIES}
        decibel={DECIBELS}
        finalAudiogramData={finalAudiogramData}
        updateFinalAudiogramData={updateFinalAudiogramData}
        noResponseObject={noResponseObject}
        selectVolumes={selectVolumes}
      />
      {isErrorFound && <DisplayError errorMessage={calculationApiError} />}
      <div className="d-flex justify-content-center">
        <div className="row w-50">
          <div className="col">
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="w-100"
              >
                Clear
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100 text-center">
                <Dropdown.Item
                  onClick={() => reSetAudiogramData()}
                  title="Clear All Audiogram"
                  disabled={!(finalAudiogramData.length > 0)}
                >
                  <i className="fa-solid fa-xmark"></i> Clear All
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => reSetAudiogramData("left")}
                  title="Clear Left Ear Audiogram"
                  disabled={
                    !(
                      finalAudiogramData.filter((obj) => obj.channel === "left")
                        .length > 0
                    )
                  }
                >
                  <i className="fa-solid fa-ear-listen"></i> Clear Left
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => reSetAudiogramData("right")}
                  title="Clear Right Ear Audiogram"
                  disabled={
                    !(
                      finalAudiogramData.filter(
                        (obj) => obj.channel === "right"
                      ).length > 0
                    )
                  }
                >
                  Clear Right <i className="fa-solid fa-ear-listen"></i>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col">
            <button
              className="btn btn-outline-secondary w-100 mb-2"
              onClick={() => handleAudiogramResultManually(true)}
              title={
                (finalAudiogramData.length > 0 ? "Edit" : "Enter") +
                " Audiogram Result Manually"
              }
            >
              <i className="fa-solid fa-ear-listen"></i>{" "}
              {finalAudiogramData.length > 0 ? "Edit" : "Enter"} Manual Result
            </button>
          </div>
          <div className="col">
            <button
              className="btn btn-outline-primary w-100"
              onClick={calculateHearScore}
              title="Calculate Hearscore"
              disabled={!(finalAudiogramData.length > 0)}
            >
              <i className="fa-solid fa-calculator"></i> Calculate Hearscore
            </button>
          </div>
        </div>
      </div>
      {show && (
        <ManualAudiogramData
          frequency={FREQUENCIES}
          handleClose={handleClose}
          setManualAudiogram={setManualAudiogram}
          audiogramData={audiogramData}
          noResponseObject={noResponseObject}
          selectVolumes={selectVolumes}
        />
      )}
      {showHearscore && (
        <HearscoreResult
          handleCloseHearscore={handleCloseHearscore}
          hearscoreResult={hearscoreResult}
          earData={earData}
        />
      )}
    </>
  );
};
export default CalculatorLayout;
