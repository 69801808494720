import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

const EditAudiogram = ({
  audiogramData,
  handleCloseEdit,
  updateFinalAudiogramData,
  noResponseObject,
  selectVolumes,
}) => {
  let [volumes, setVolumes] = useState(selectVolumes);
  let [editAudiogramData, setEditAudiogramData] = useState(audiogramData);

  let handleVolume = (volume) => {
    let noResponse = false;
    if (volume == "noresponse") {
      noResponse = true;
      volume = 80;
    }

    setEditAudiogramData((prevItem) => ({
      ...prevItem,
      y: volume,
      noResponse,
    }));
  };

  let handleSaveAudiogram = () => {
    updateFinalAudiogramData(editAudiogramData);
    handleCloseEdit();
  };

  let handleReSetAudiogram = () => {
    setEditAudiogramData((prevItem) => ({
      ...prevItem,
      y: null,
    }));
  };

  return (
    <>
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Edit Audiogram Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-sm-3 text-end">
                <label>Channel: </label>
              </div>
              <div className="col">
                <label className="h6">{editAudiogramData.channel}</label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 text-end">
                <label>Frequency: </label>
              </div>
              <div className="col">
                <label className="h6">{editAudiogramData.x} Hz</label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 text-end">
                <label>Volume: </label>
              </div>
              <div className="col manual-audiogram">
                <Select
                  className="w-50 float-start"
                  value={
                    !(
                      editAudiogramData.y == null ||
                      editAudiogramData.y == undefined
                    )
                      ? editAudiogramData.noResponse
                        ? noResponseObject
                        : {
                            label: editAudiogramData.y + "dB",
                            value: editAudiogramData.y,
                          }
                      : null
                  }
                  onChange={(v) => handleVolume(v.value)}
                  options={volumes}
                />
                <button
                  className="btn btn-secondary  rounded-0 rounded-end"
                  onClick={() => handleReSetAudiogram()}
                  title={`Clear ${editAudiogramData.channel} Ear ${editAudiogramData.x} Frequency`}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => handleCloseEdit()}
            title="Reset Audiogram Data"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleSaveAudiogram()}
            title="Save Audiogram Data"
            disabled={
              JSON.stringify(audiogramData) ===
              JSON.stringify(editAudiogramData)
            }
          >
            <i className="fa-solid fa-check"></i> Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditAudiogram;
