import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../Authentication/authConfig";

const HandleSignIn = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <>
      <h5>
        <center>
          <div className="container mt-5">
            <div className="row d-flex justify-content-center">
              <div className="col-md-4">
                <div className="card px-5 py-5 shadow">
                  <div className="form-data">
                    <div className="text-center">
                      <img
                        src="/Images/logo.png"
                        className="w-100"
                        title="logo"
                        alt="logo"
                      />
                    </div>
                    <div>
                      <p className="my-3"> HearScore Calculator </p>
                      <button
                        className="btn btn-dark w-100"
                        onClick={() => handleLogin("popup")}
                        title="Sign in"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </center>
      </h5>
    </>
  );
};
export default HandleSignIn;
