import React, { useState, useEffect } from "react";
import EditAudiogram from "./EditAudiogram";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  // Tooltip,
  Legend,
  PointElement,
  LineElement,
  registerables as registerablesJS,
} from "chart.js";
import { Chart } from "react-chartjs-2";
ChartJS.register(...registerablesJS);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  // Tooltip, //to display tooltip at point
  Legend //to display Legend
);

const AudiogramChart = ({
  frequency,
  decibel,
  finalAudiogramData,
  updateFinalAudiogramData,
  noResponseObject,
  selectVolumes,
}) => {
  const FREQUENCIES = frequency;
  const DECIBELS = decibel;

  let [audiogramData, setAudiogramData] = useState([]);

  let [showEdit, setShowEdit] = useState(false);
  let [editData, setEditData] = useState(null);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  useEffect(() => {
    setAudiogramData(finalAudiogramData.sort((a, b) => a.x - b.x));
  }, [finalAudiogramData]);

  const [channel, setChannel] = useState("left");

  const leftImg = new Image();
  leftImg.src = "/Images/Left-no-response.PNG";
  const rightImg = new Image();
  rightImg.src = "/Images/Right-no-response.PNG";

  const [chartData, setChartData] = useState({
    datasets: [
      {
        type: "line",
        pointStyle: "crossRot",
        borderColor: "rgb(51, 3, 238)",
        backgroundColor: "transparent",
        radius: 8,
        borderWidth: 3,
        showLine: true,
        spanGaps: true,
        tension: 0,
        label: "Left Ear",
        data: [],
      },
      {
        type: "line",
        pointStyle: "circle",
        borderColor: "rgb(221, 3, 68)",
        backgroundColor: "transparent",
        radius: 8,
        borderWidth: 3,
        showLine: true,
        spanGaps: true,
        tension: 0,
        label: "Right Ear",
        data: [],
      },
      {
        pointStyle: leftImg,
        // pointStyle: "crossRot",
        borderColor: "rgb(51, 3, 238)",
        backgroundColor: "transparent",
        radius: 15,
        borderWidth: 3,
        type: "bubble",
        label: "Left Ear NR",
        data: [],
      },
      {
        pointStyle: rightImg,
        // pointStyle: "circle",
        borderColor: "rgb(221, 3, 68)",
        backgroundColor: "transparent",
        radius: 15,
        borderWidth: 3,
        type: "bubble",
        label: "Right Ear NR",
        data: [],
      },
      // {
      //   // position indicator
      //   type: "bubble",
      //   pointStyle: "crossRot",
      //   borderColor: "rgba(51, 3, 238, 0.5)",
      //   backgroundColor: "transparent",
      //   radius: 8,
      //   borderWidth: 3,
      //   data: [{ x: FREQUENCIES[2], y: DECIBELS[12] }],
      // },
    ],
  });

  useEffect(() => {
    const leftData = audiogramData.filter((obj) => obj.channel === "left");
    const rightData = audiogramData.filter((obj) => obj.channel === "right");

    const leftNRData = audiogramData.filter(
      (obj) => obj.channel === "left" && obj.noResponse === true
    );
    const rightNRData = audiogramData.filter(
      (obj) => obj.channel === "right" && obj.noResponse === true
    );

    setChartData({
      ...chartData,
      datasets: [
        {
          ...chartData.datasets[0],
          data: leftData,
        },
        {
          ...chartData.datasets[1],
          data: rightData,
        },
        {
          ...chartData.datasets[2],
          data: leftNRData,
        },
        {
          ...chartData.datasets[3],
          data: rightNRData,
        },
        // {
        //   ...chartData.datasets[5],
        // },
      ],
    });
  }, [audiogramData]);

  const handlePointClick = (event, elements) => {
    if (elements.length === 0) {
      const chartInstance = event.chart;

      let xPos = chartInstance.scales["x"].getValueForPixel(
        event.x - chartInstance.chartArea.top
      );

      let yPos = chartInstance.scales["y"].getValueForPixel(
        event.y - chartInstance.chartArea.top
      );

      if (xPos > 0 && FREQUENCIES[xPos - 1]) {
        updateFinalAudiogramData({
          x: FREQUENCIES[xPos - 1],
          y: DECIBELS[yPos + 2],
          channel,
          noResponse: false,
        });
      } else {
        console.log("no need");
      }
    } else {
      let elementData = elements[0].element.$context.raw;
      let channelAudiogram = audiogramData.filter(
        (obj) =>
          obj.channel === channel &&
          obj.x === elementData.x &&
          obj.y === elementData.y
      );
      if (channelAudiogram.length !== 0) {
        setEditData(channelAudiogram[0]);
        handleShowEdit();
      } else {
        updateFinalAudiogramData({
          x: elementData.x,
          y: elementData.y,
          channel,
          noResponse: false,
        });
      }
    }
  };

  const options = {
    scales: {
      x: {
        type: "category",
        labels: [""].concat(FREQUENCIES).concat([""]),
        position: "top",
        title: {
          display: true,
          text: "Frequency (Hz)",
        },
      },
      y: {
        type: "category",
        labels: DECIBELS,
        title: {
          display: true,
          text: "Volume (dB)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
    },
    onClick: (event, elements) => handlePointClick(event, elements),
  };

  let handleChannelChange = (channel) => {
    setChannel(channel);
  };

  return (
    <>
      <div className={"row"}>
        <div className={"col-md-6 offset-md-3"}>
          <div className={"btn-group d-flex"}>
            <label
              className={
                "btn btn-outline-primary w-100" +
                (channel === "left" ? " active" : "")
              }
              onClick={() => handleChannelChange("left")}
            >
              <span className={"fas fa-chevron-left"} /> Left Ear
            </label>
            <label
              className={
                "btn btn-outline-primary w-100" +
                (channel === "right" ? " active" : "")
              }
              onClick={() => handleChannelChange("right")}
            >
              Right Ear <span className={"fas fa-chevron-right"} />
            </label>
          </div>
        </div>
      </div>
      <div className="my-2 m-auto cursor-pointer" style={{ width: "70%" }}>
        <Chart data={chartData} options={options} />
      </div>
      {showEdit && editData && (
        <EditAudiogram
          audiogramData={editData}
          handleCloseEdit={handleCloseEdit}
          updateFinalAudiogramData={updateFinalAudiogramData}
          noResponseObject={noResponseObject}
          selectVolumes={selectVolumes}
        />
      )}
    </>
  );
};

export default AudiogramChart;
