import React from "react";
import "./App.css";

import CalculatorLayout from "./Component/CalculatorLayout";
import Header from "./Component/Header";
import HandleSignIn from "./Component/HandleSignIn";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

const App = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Header />
        <main className="flex-shrink-0 mb-3">
          <div className="container">
            <div className="text-center mb-3">
              <span className="h3">Hearscore Calculator</span>
            </div>
            <CalculatorLayout />
          </div>
        </main>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <HandleSignIn />
      </UnauthenticatedTemplate>
    </>
  );
};
export default App;
