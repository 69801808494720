import React, { useState, useEffect } from "react";
import Select from "react-select";

import Modal from "react-bootstrap/Modal";

const ManualAudiogramData = ({
  frequency,
  handleClose,
  setManualAudiogram,
  audiogramData,
  noResponseObject,
  selectVolumes,
}) => {
  let [volumes, setVolumes] = useState(selectVolumes);
  let [manualAudiogramData, setManualAudiogramData] = useState(audiogramData);

  const FREQUENCIES = frequency;

  let handleVolume = (volume, frequency, channel) => {
    let manualAudiogramDataData = manualAudiogramData;
    let noResponse = false;
    if (volume == "noresponse") {
      noResponse = true;
      volume = 80;
    }
    let isExist = manualAudiogramDataData.find(
      (c) => c.channel == channel && c.x == frequency
    );
    if (isExist) {
      setManualAudiogramData((prevState) =>
        prevState.map((item) =>
          item.channel == channel && item.x == frequency
            ? { ...item, y: volume, noResponse }
            : item
        )
      );
    } else {
      let newAudiogram = {
        x: frequency,
        y: volume,
        channel,
        noResponse,
      };
      setManualAudiogramData((prevItems) =>
        [...prevItems, newAudiogram].sort((a, b) => a.x - b.x)
      );
    }
  };

  let reSetManualAudiogramData = (channel, frequency) => {
    if (channel && frequency) {
      setManualAudiogramData((prevItems) =>
        prevItems.filter(
          (obj) => !(obj.channel == channel && obj.x == frequency)
        )
      );
    } else if (channel) {
      setManualAudiogramData((prevItems) =>
        prevItems.filter((obj) => obj.channel !== channel)
      );
    } else {
      setManualAudiogramData([]);
    }
  };

  let copyAudiogramData = (channelFrom, channelTo) => {
    if (channelFrom && channelTo) {
      // get data for channelFrom
      let channelAudiogramData = manualAudiogramData.filter(
        (obj) => obj.channel == channelFrom
      );
      // clear other channel(channelTo) data
      setManualAudiogramData((prevItems) =>
        prevItems.filter((obj) => obj.channel !== channelTo)
      );
      // copy channelFrom data to channelTo
      let otherChannelAudiogramData = channelAudiogramData.map((obj) => ({
        ...obj,
        channel: channelTo,
      }));
      // merge both data
      const mergedAudiogramData = [
        ...channelAudiogramData,
        ...otherChannelAudiogramData,
      ];
      setManualAudiogramData(mergedAudiogramData);
    }
  };

  function arraysAreEqualIgnoringChannel(arr1, arr2) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Iterate through each element of the arrays
    for (let i = 0; i < arr1.length; i++) {
      // Check if all properties except 'channel' are equal
      const { channel: c1, ...rest1 } = arr1[i];
      const { channel: c2, ...rest2 } = arr2[i];

      if (JSON.stringify(rest1) !== JSON.stringify(rest2)) {
        return false;
      }
    }

    // If all elements are equal (ignoring 'channel'), return true
    return true;
  }

  return (
    <>
      <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Manual Audiogram Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row py-2">
            <div className="col-2 text-start d-flex justify-content-between align-items-center">
              <strong>Frequency</strong>
            </div>
            <div className="col-5 text-start ps-4 pe-5 d-flex justify-content-between align-items-center">
              <strong>Left Volume</strong>
              <button
                className="btn btn-secondary me-2"
                onClick={() => reSetManualAudiogramData("left")}
                title="Clear Left Ear"
                disabled={
                  !(
                    manualAudiogramData.filter((obj) => obj.channel === "left")
                      .length > 0
                  )
                }
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="col-5 text-start ps-4 pe-5 d-flex justify-content-between align-items-center">
              <strong>Right Volume</strong>
              <button
                className="btn btn-secondary me-2"
                onClick={() => reSetManualAudiogramData("right")}
                title="Clear Right Ear"
                disabled={
                  !(
                    manualAudiogramData.filter((obj) => obj.channel === "right")
                      .length > 0
                  )
                }
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
          {FREQUENCIES.map((frequency, i) => {
            const selectedFrequencyVolumeLeft = manualAudiogramData.find(
              (fv) => {
                return fv.channel === "left" && fv.x === frequency;
              }
            );
            let leftSelectedValue = null;
            if (selectedFrequencyVolumeLeft) {
              if (selectedFrequencyVolumeLeft.noResponse) {
                leftSelectedValue = noResponseObject;
              } else {
                leftSelectedValue = {
                  label: selectedFrequencyVolumeLeft.y + "dB",
                  value: selectedFrequencyVolumeLeft.y,
                };
              }
            }
            const selectedFrequencyVolumeRight = manualAudiogramData.find(
              (fv) => {
                return fv.channel === "right" && fv.x === frequency;
              }
            );
            let rightSelectedValue = null;
            if (selectedFrequencyVolumeRight) {
              if (selectedFrequencyVolumeRight.noResponse) {
                rightSelectedValue = noResponseObject;
              } else {
                rightSelectedValue = {
                  label: selectedFrequencyVolumeRight.y + "dB",
                  value: selectedFrequencyVolumeRight.y,
                };
              }
            }
            return (
              <div className="form-group row py-2 manual-audiogram" key={i}>
                <div key={frequency} className={"col-2 py-2 text-start"}>
                  {frequency}Hz
                </div>
                <div className="col-5 text-left px-4">
                  <Select
                    className="w-75 float-start"
                    value={leftSelectedValue}
                    onChange={(v) => handleVolume(v.value, frequency, "left")}
                    options={volumes}
                  />
                  <button
                    className="btn btn-secondary  rounded-0 rounded-end"
                    onClick={() => reSetManualAudiogramData("left", frequency)}
                    title={`Clear Left Ear ${frequency} Frequency`}
                    disabled={!leftSelectedValue}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <div className="col-5 text-left px-4">
                  <Select
                    className="w-75 float-start"
                    value={rightSelectedValue}
                    onChange={(v) => handleVolume(v.value, frequency, "right")}
                    options={volumes}
                  />
                  <button
                    className="btn btn-secondary rounded-0 rounded-end"
                    onClick={() => reSetManualAudiogramData("right", frequency)}
                    title={`Clear Left Ear ${frequency} Frequency`}
                    disabled={!rightSelectedValue}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
            );
          })}
          <div className="form-group row py-2">
            <div className="col-2 text-start d-flex justify-content-between align-items-center"></div>
            <div className="col-5 ps-4 pe-5 d-flex justify-content-end align-items-center">
              <button
                className="btn btn-secondary me-2"
                onClick={() => copyAudiogramData("left", "right")}
                title="Copy to Right Ear"
                disabled={
                  !(
                    manualAudiogramData.filter((obj) => obj.channel === "left")
                      .length > 0 &&
                    !arraysAreEqualIgnoringChannel(
                      manualAudiogramData.filter(
                        (obj) => obj.channel === "left"
                      ),
                      manualAudiogramData.filter(
                        (obj) => obj.channel === "right"
                      )
                    )
                  )
                }
              >
                <i className="fa-solid fa-copy"></i>
                <i className="fa-solid fa-right-long"></i>
              </button>
            </div>
            <div className="col-5 ps-4 pe-5 d-flex justify-content-start align-items-center">
              <button
                className="btn btn-secondary me-2"
                onClick={() => copyAudiogramData("right", "left")}
                title="Copy to Left Ear"
                disabled={
                  !(
                    manualAudiogramData.filter((obj) => obj.channel === "right")
                      .length > 0 &&
                    !arraysAreEqualIgnoringChannel(
                      manualAudiogramData.filter(
                        (obj) => obj.channel === "left"
                      ),
                      manualAudiogramData.filter(
                        (obj) => obj.channel === "right"
                      )
                    )
                  )
                }
              >
                <i className="fa-solid fa-left-long"></i>
                <i className="fa-solid fa-copy"></i>
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => handleClose()}
            title="Cancel"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setManualAudiogram(manualAudiogramData)}
            title="Save Audiogram Results"
            disabled={
              JSON.stringify(audiogramData) ===
              JSON.stringify(manualAudiogramData)
            }
          >
            <i className="fa-solid fa-check"></i> Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ManualAudiogramData;
